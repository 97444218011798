import React from "react";

interface DebuggerProps {
  data: any;
  label?: string;
}

const Debugger = ({ data, label = "Debug Info" }: DebuggerProps) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50px",
        left: "10px",
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        zIndex: 1000,
        fontFamily: "monospace",
      }}
    >
      <pre>
        {label}: {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  );
};

export default Debugger;

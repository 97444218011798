export default function generateUniqueId(): string {
  // Get current timestamp
  const timestamp = Date.now().toString(36);

  // Generate 3 random characters
  const randomChars = Math.random().toString(36).substring(2, 5).toUpperCase();

  // Combine and ensure 6 characters
  const combined = (timestamp + randomChars).slice(-6).toUpperCase();

  return combined;
}

import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext.tsx";
import { auth } from "../../config/firestore";
import { signOut } from "firebase/auth";

const Menu: React.FC = () => {
  const { user, userLoggedIn } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const menuContainerStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    padding: "12px 20px",
  };

  const menuStyle: React.CSSProperties = {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    backdropFilter: "blur(8px)",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    minWidth: "200px",
    padding: "8px 12px",
  };

  const userEmailStyle: React.CSSProperties = {
    fontSize: "14px",
    color: "#374151",
    fontWeight: 500,
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  };

  const userIconStyle: React.CSSProperties = {
    width: "24px",
    height: "24px",
    backgroundColor: "#3b82f6",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "12px",
    fontWeight: 600,
  };

  const dropdownStyle: React.CSSProperties = {
    position: "absolute",
    top: "100%",
    left: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    minWidth: "200px",
    padding: "8px 0",
    opacity: isDropdownOpen ? 1 : 0,
    visibility: isDropdownOpen ? "visible" : "hidden",
    transform: `translateY(${isDropdownOpen ? "0" : "-8px"})`,
    transition: "all 0.2s ease",
  };

  const dropdownItemStyle: React.CSSProperties = {
    padding: "10px 16px",
    fontSize: "14px",
    color: "#374151",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    backgroundColor: "transparent",
    width: "100%",
    border: "none",
    textAlign: "left",
  };

  const handleLogout = async () => {
    try {
      setIsDropdownOpen(false);
      await signOut(auth);
      window.location.href = "/";
    } catch (error) {}
  };

  if (!userLoggedIn || !user) return null;

  const userInitial = user.email?.[0].toUpperCase() || "U";

  return (
    <div
      style={menuContainerStyle}
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      <div style={menuStyle} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <div style={userIconStyle}>{userInitial}</div>
        <span style={userEmailStyle}>{user?.email}</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 20 20"
          fill="currentColor"
          style={{
            transform: `rotate(${isDropdownOpen ? "180deg" : "0deg"})`,
            transition: "transform 0.2s ease",
          }}
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div style={dropdownStyle}>
        <div
          style={dropdownItemStyle}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#f3f4f6";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "transparent";
          }}
        >
          <svg width="16" height="16" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clipRule="evenodd"
            />
          </svg>
          Profile
        </div>
        <div
          style={{
            ...dropdownItemStyle,
            color: "#dc2626",
          }}
          onClick={handleLogout}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#fee2e2";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "transparent";
          }}
        >
          <svg width="16" height="16" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M3 3a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1H3zm11 3a1 1 0 10-2 0v6a1 1 0 102 0V6zm-8 2a1 1 0 011-1h2a1 1 0 110 2H7a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
          Logout
        </div>
      </div>
    </div>
  );
};

export default Menu;

import {
  collection,
  addDoc,
  Timestamp,
  setDoc,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { db } from "../../config/firestore.js";

export const createGameSession = async (sessionId, userEmail) => {
  try {
    const stack = new Error().stack;
    console.log("createGameSession called from:", {
      sessionId,
      userEmail,
      stack,
      timestamp: new Date().toISOString(),
      caller: stack?.split("\n")[2]?.trim(),
    });

    const gameSession = {
      sessionId,
      userEmail,
      currentQuestion: 0,
      questions: [],
      questionHistory: [],
      score: 0,
      startedAt: Timestamp.now(),
      finishedAt: null,
      status: "active",
    };

    await setDoc(doc(db, "games", sessionId), gameSession);
    return sessionId;
  } catch (e) {
    console.error("Error in createGameSession:", e);
    throw e;
  }
};

export const updateGameSession = async (gameId, gameData) => {
  try {
    const docRef = doc(db, "games", gameId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error("Game session not found:", gameId);
      throw new Error("No game session found");
    }

    console.log("Updating game session:", {
      gameId,
      currentData: docSnap.data(),
      newData: gameData,
    });

    await updateDoc(docRef, {
      ...gameData,
      updatedAt: Timestamp.now(),
    });
  } catch (e) {
    console.error("Error updating game session:", e);
    throw e;
  }
};

export const updateGameScore = async (gameId, userId, score) => {
  try {
    const gameRef = doc(db, "games", gameId);
    await updateDoc(gameRef, {
      score: score,
      updatedAt: new Date().toISOString(),
      userId: userId,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const updateQuestionHistory = async (gameId, questionHistoryEntry) => {
  try {
    await updateDoc(doc(db, "games", gameId), {
      questionHistory: arrayUnion(questionHistoryEntry),
      updatedAt: Timestamp.now(),
    });
  } catch (e) {
    throw e;
  }
};

import React from "react";
import "./style.css";
import GameControls from "../../components/gamecontrols/index.tsx";
import { createGameSession } from "../../firebase/services/gameService.js";
import { useGameFlow } from "../../context/GameFlowContext.tsx";
import generateUniqueId from "../../components/utils/index.tsx";
import { useAuth } from "../../context/AuthContext.tsx";

const PlayAgainLayout = ({ setInGame }) => {
  const { user } = useAuth();
  const { gameFlow, setGameFlow } = useGameFlow();

  const handlePlay = async () => {
    try {
      // Clear any existing game session first
      localStorage.removeItem("currentGameSession");

      // Generate new session ID
      const gameId = generateUniqueId();
      const userId = user?.email || "anonymous";

      // Create initial game session
      await createGameSession(gameId, userId);

      // Make sure localStorage is updated before changing game state
      localStorage.setItem("currentGameSession", gameId);

      // Force a small delay to ensure localStorage is updated
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Reset game state before starting new game
      setGameFlow({
        ...gameFlow,
        playing: false,
        gameOver: false,
      });

      // Then set inGame to true
      setInGame(true);
    } catch (error) {
      console.error("Error starting new game:", error);
    }
  };

  const handleLeave = () => {
    window.close();
  };

  return (
    <div className="playAgainLayout">
      <GameControls onPlay={handlePlay} onLeave={handleLeave} />
    </div>
  );
};

export default PlayAgainLayout;

import React, { useEffect, useState } from "react";
import Headline from "../../components/headline/index.tsx";
import Logo from "../../components/logo/index.tsx";
import { useSound } from "../../components/sound/index.tsx";
import { createGameSession } from "../../firebase/services/gameService.js";
import GameControls from "../../components/gamecontrols/index.tsx";
import generateUniqueId from "../../components/utils/index.tsx";
import { useAuth } from "../../context/AuthContext.tsx";
const PlayLayout = ({ setInGame }) => {
  const { playSound, stopSound } = useSound();
  const [gameStatus, setGameStatus] = useState("idle");
  const { user } = useAuth();

  useEffect(() => {
    // Add click listener for first interaction
    const handleFirstInteraction = () => {
      if (gameStatus === "idle") {
        playSound("ambient");
      }
      // Remove listener after first interaction
      document.removeEventListener("click", handleFirstInteraction);
      document.removeEventListener("keydown", handleFirstInteraction);
    };

    document.addEventListener("click", handleFirstInteraction);
    document.addEventListener("keydown", handleFirstInteraction);

    return () => {
      document.removeEventListener("click", handleFirstInteraction);
      document.removeEventListener("keydown", handleFirstInteraction);
      if (gameStatus !== "idle") {
        stopSound("ambient");
      }
    };
  }, [gameStatus]);

  const handlePlay = async () => {
    try {
      setGameStatus("starting");
      const userEmail = user?.email || "anonymous";

      // Clear any existing game session first
      localStorage.removeItem("currentGameSession");

      // Generate new session ID
      const sessionId = generateUniqueId();

      // Create initial game session
      await createGameSession(sessionId, userEmail);

      // Store new gameId in localStorage
      localStorage.setItem("currentGameSession", sessionId);

      setGameStatus("playing");
      setInGame(true);
    } catch (error) {
      setGameStatus("error");
    }
  };

  const handleLeave = () => {
    setGameStatus("leaving");
    window.close();
  };

  return (
    <div className="playGameLayout">
      {gameStatus === "error" && (
        <div style={{ color: "red" }}>Failed to start game</div>
      )}
      <Logo
        alt="Logo"
        style={{
          display: "block",
          margin: "0 auto",
          position: "absolute",
          top: "15px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />
      <Headline
        style={{ top: "90px" }}
        text="If you take the blue pill, you'll stay at your current job; if you take the red pill, you'll join big tech and see high salary scales."
      />
      <GameControls onPlay={handlePlay} onLeave={handleLeave} />
    </div>
  );
};

export default PlayLayout;

import React, { useState, useEffect } from "react";
import Button from "../../components/button/index.tsx";
import Modal from "../../components/modal/index.tsx";
import { useAuth } from "../../context/AuthContext.tsx";
import { auth } from "../../config/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import Footer from "../footer/index.tsx";

interface GameControlsProps {
  onPlay: () => Promise<void>;
  onLeave: () => void;
}

const GameControls: React.FC<GameControlsProps> = ({ onPlay, onLeave }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lockoutEndTime, setLockoutEndTime] = useState<number | null>(null);

  // Check for existing lockout on component mount
  useEffect(() => {
    const storedLockoutEnd = localStorage.getItem("lockoutEndTime");
    if (storedLockoutEnd) {
      const endTime = parseInt(storedLockoutEnd);
      if (endTime > Date.now()) {
        setIsLocked(true);
        setLockoutEndTime(endTime);
      } else {
        localStorage.removeItem("lockoutEndTime");
      }
    }
  }, []);

  // Handle lockout timer
  useEffect(() => {
    if (isLocked && lockoutEndTime) {
      const timer = setInterval(() => {
        if (Date.now() >= lockoutEndTime) {
          setIsLocked(false);
          setLockoutEndTime(null);
          setLoginAttempts(0);
          localStorage.removeItem("lockoutEndTime");
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLocked, lockoutEndTime]);

  const handleLockout = () => {
    const lockoutDuration = 5 * 60 * 1000; // 5 minutes
    const endTime = Date.now() + lockoutDuration;
    setIsLocked(true);
    setLockoutEndTime(endTime);
    localStorage.setItem("lockoutEndTime", endTime.toString());
  };

  const validatePassword = (pass: string): boolean => {
    // At least 6 characters, 1 uppercase, 1 lowercase, 1 number
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    return passwordRegex.test(pass);
  };

  const sanitizeEmail = (email: string): string => {
    return email.trim().toLowerCase();
  };

  const handlePlayClick = () => {
    if (userLoggedIn) {
      onPlay();
    } else {
      setIsModalOpen(true);
    }
  };

  const handleGoogleSignIn = async () => {
    setError("");
    setSuccessMessage("");
    setIsLoading(true);

    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setIsModalOpen(false);
      onPlay();
    } catch (err: any) {
      let errorMessage = "Google sign-in failed. Please try again.";
      if (err.code === "auth/account-exists-with-different-credential") {
        errorMessage =
          "An account already exists with this email. Please use your existing login method.";
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (isLocked) {
      const remainingTime = Math.ceil((lockoutEndTime! - Date.now()) / 1000);
      setError(
        `Account is temporarily locked. Please try again in ${remainingTime} seconds.`
      );
      return;
    }

    const sanitizedEmail = sanitizeEmail(email);

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 6 characters long and include uppercase, lowercase, and numbers"
      );
      return;
    }

    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, sanitizedEmail, password);
      setIsModalOpen(false);
      setLoginAttempts(0);
      onPlay();
    } catch (err: any) {
      setLoginAttempts((prev) => prev + 1);

      if (loginAttempts >= 4) {
        handleLockout();
        setError("Too many failed attempts. Account locked for 5 minutes.");
        return;
      }

      let errorMessage = "Authentication failed";
      switch (err.code) {
        case "auth/user-not-found":
        case "auth/wrong-password":
          errorMessage = "Invalid email or password.";
          break;
        case "auth/invalid-email":
          errorMessage = "Please enter a valid email address.";
          break;
        default:
          errorMessage = "An error occurred. Please try again later.";
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    const sanitizedEmail = sanitizeEmail(email);

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 6 characters long and include uppercase, lowercase, and numbers"
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, sanitizedEmail, password);
      setSuccessMessage("Account created successfully. Please log in.");
      setIsSignUp(false);
      setPassword("");
      setConfirmPassword("");
      setEmail("");
      setError("");
    } catch (err: any) {
      let errorMessage = "Registration failed";
      switch (err.code) {
        case "auth/email-already-in-use":
          errorMessage =
            "Invalid email or password. Please check your credentials and try again.";
          break;
        case "auth/invalid-email":
          errorMessage = "Please enter a valid email address.";
          break;
        default:
          errorMessage = "An error occurred. Please try again later.";
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setError("");
  };

  const tabStyle = (active: boolean): React.CSSProperties => ({
    flex: 1,
    padding: "10px",
    backgroundColor: active ? "#f3f4f6" : "transparent",
    border: "none",
    borderBottom: active ? "2px solid #3b82f6" : "1px solid #e5e7eb",
    color: active ? "#1f2937" : "#6b7280",
    fontWeight: active ? "600" : "500",
    cursor: "pointer",
    transition: "all 0.2s ease",
    fontSize: "14px",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
  });

  const renderSignInForm = () => (
    <form onSubmit={handleSignIn}>
      <div className="form-group">
        <label
          htmlFor="email"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#1f2937",
            letterSpacing: "0.01em",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          }}
        >
          Email
        </label>
        <input
          style={{
            width: "100%",
            padding: "10px 12px",
            border: "1px solid #d1d5db",
            borderRadius: "8px",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#f9fafb",
            marginBottom: "16px",
            transition: "all 0.2s ease",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          onFocus={(e) => {
            e.target.style.borderColor = "#3b82f6";
            e.target.style.boxShadow = "0 0 0 3px rgba(59, 130, 246, 0.1)";
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#d1d5db";
            e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.05)";
          }}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="password"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#1f2937",
            letterSpacing: "0.01em",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          }}
        >
          Password
        </label>
        <input
          style={{
            width: "100%",
            padding: "10px 12px",
            border: "1px solid #d1d5db",
            borderRadius: "8px",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#f9fafb",
            marginBottom: "16px",
            transition: "all 0.2s ease",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
          onFocus={(e) => {
            e.target.style.borderColor = "#3b82f6";
            e.target.style.boxShadow = "0 0 0 3px rgba(59, 130, 246, 0.1)";
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#d1d5db";
            e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.05)";
          }}
        />
      </div>
      <div className="form-buttons">
        <Button
          type="submit"
          backgroundColor="green"
          textColor="white"
          style={{
            width: "100%",
            marginBottom: "10px",
            opacity: isLoading ? "0.7" : "1",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          disabled={isLoading}
        >
          {isLoading ? "Please wait..." : "Login"}
        </Button>
        <Button
          type="button"
          onClick={handleGoogleSignIn}
          backgroundColor="white"
          textColor="black"
          style={{
            width: "100%",
            border: "1px solid #e5e7eb",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            padding: "10px 16px",
            opacity: isLoading ? "0.7" : "1",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          disabled={isLoading}
        >
          <label
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#374151",
              letterSpacing: "0.01em",
              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
              cursor: isLoading ? "not-allowed" : "pointer",
              margin: 0,
            }}
          >
            {isLoading ? "Please wait..." : "Continue with Google"}
          </label>
        </Button>
      </div>
    </form>
  );

  const renderSignUpForm = () => (
    <form onSubmit={handleSignUp}>
      <div className="form-group">
        <label
          htmlFor="email"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#1f2937",
            letterSpacing: "0.01em",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          }}
        >
          Email
        </label>
        <input
          style={{
            width: "100%",
            padding: "10px 12px",
            border: "1px solid #d1d5db",
            borderRadius: "8px",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#f9fafb",
            marginBottom: "16px",
            transition: "all 0.2s ease",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          onFocus={(e) => {
            e.target.style.borderColor = "#3b82f6";
            e.target.style.boxShadow = "0 0 0 3px rgba(59, 130, 246, 0.1)";
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#d1d5db";
            e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.05)";
          }}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="password"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#1f2937",
            letterSpacing: "0.01em",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          }}
        >
          Password
        </label>
        <input
          style={{
            width: "100%",
            padding: "10px 12px",
            border: "1px solid #d1d5db",
            borderRadius: "8px",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#f9fafb",
            marginBottom: "16px",
            transition: "all 0.2s ease",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
          onFocus={(e) => {
            e.target.style.borderColor = "#3b82f6";
            e.target.style.boxShadow = "0 0 0 3px rgba(59, 130, 246, 0.1)";
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#d1d5db";
            e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.05)";
          }}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="confirmPassword"
          style={{
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#1f2937",
            letterSpacing: "0.01em",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          }}
        >
          Confirm Password
        </label>
        <input
          style={{
            width: "100%",
            padding: "10px 12px",
            border: "1px solid #d1d5db",
            borderRadius: "8px",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#f9fafb",
            marginBottom: "16px",
            transition: "all 0.2s ease",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm your password"
          required
          onFocus={(e) => {
            e.target.style.borderColor = "#3b82f6";
            e.target.style.boxShadow = "0 0 0 3px rgba(59, 130, 246, 0.1)";
          }}
          onBlur={(e) => {
            e.target.style.borderColor = "#d1d5db";
            e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.05)";
          }}
        />
      </div>
      <div className="form-buttons">
        <Button
          type="submit"
          backgroundColor="green"
          textColor="white"
          style={{
            width: "100%",
            marginBottom: "10px",
            opacity: isLoading ? "0.7" : "1",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          disabled={isLoading}
        >
          {isLoading ? "Please wait..." : "Sign Up"}
        </Button>
        <Button
          type="button"
          onClick={handleGoogleSignIn}
          backgroundColor="white"
          textColor="black"
          style={{
            width: "100%",
            border: "1px solid #e5e7eb",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            padding: "10px 16px",
            opacity: isLoading ? "0.7" : "1",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          disabled={isLoading}
        >
          <label
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#374151",
              letterSpacing: "0.01em",
              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
              cursor: isLoading ? "not-allowed" : "pointer",
              margin: 0,
            }}
          >
            {isLoading ? "Please wait..." : "Continue with Google"}
          </label>
        </Button>
      </div>
    </form>
  );

  return (
    <>
      <div className="container">
        <div className="box">
          <Button
            backgroundColor="red"
            textColor="white"
            onClick={handlePlayClick}
          >
            Play
          </Button>
        </div>
        <div className="box">
          <Button backgroundColor="blue" textColor="white" onClick={onLeave}>
            Leave
          </Button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          resetForm();
          setSuccessMessage("");
        }}
        title="temet nosce"
        width="400px"
      >
        <div className="login-form">
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <button
              type="button"
              style={tabStyle(!isSignUp)}
              onClick={() => {
                setIsSignUp(false);
                setError("");
                setSuccessMessage("");
              }}
            >
              Login
            </button>
            <button
              type="button"
              style={tabStyle(isSignUp)}
              onClick={() => {
                setIsSignUp(true);
                setError("");
                setSuccessMessage("");
              }}
            >
              Sign Up
            </button>
          </div>

          {error && (
            <div
              style={{
                padding: "12px",
                backgroundColor: "#fee2e2",
                border: "1px solid #fecaca",
                borderRadius: "6px",
                color: "#dc2626",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              {error}
            </div>
          )}

          {successMessage && (
            <div
              style={{
                padding: "12px",
                backgroundColor: "#dcfce7",
                border: "1px solid #bbf7d0",
                borderRadius: "6px",
                color: "#16a34a",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              {successMessage}
            </div>
          )}

          {isSignUp ? renderSignUpForm() : renderSignInForm()}
        </div>
      </Modal>

      <Footer />
    </>
  );
};

export default GameControls;

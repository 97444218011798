import React, { useState, useEffect, useMemo, useCallback } from "react";
import moneyPyramid from "../../mock/moneyPyramid.json";
import EndGameLayout from "../endgame/index.tsx";
import MoneyPyramid from "../../components/moneypyramid/index.tsx";
import Trivia from "../../components/trivia/index.tsx";
import {
  getRandomQuestion,
  markQuestionAsAnswered,
} from "../../firebase/services/questionService.js";
import Footer from "../../components/footer/index.tsx";
import Debugger from "../../components/debugger/index.tsx";
import { SoundControl, SoundProvider } from "../../components/sound/index.tsx";
import { useSound } from "../../components/sound/index.tsx";
import { useGameFlow } from "../../context/GameFlowContext.tsx";
import {
  createGameSession,
  updateGameScore,
} from "../../firebase/services/gameService.js";
import { useAuth } from "../../context/AuthContext.tsx";
import generateUniqueId from "../../components/utils/index.tsx";
import {
  updateGameSession,
  updateQuestionHistory,
} from "../../firebase/services/gameService.js";
import { getFirestore, arrayUnion } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firestore.js";

interface Question {
  questionText: string;
  answerOptions: string[];
  id?: string;
  correctAnswer: string;
}

const InGameLayout = () => {
  const { gameFlow, setGameFlow } = useGameFlow();
  const { user } = useAuth();
  const [question, setQuestion] = useState<Question>({
    questionText: "",
    answerOptions: [],
    correctAnswer: "",
  });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [safeLevel, setSafeLevel] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showBanner, setShowBanner] = useState(false);
  const [sessionId, setSessionId] = useState(() => {
    return localStorage.getItem("currentGameSession") || generateUniqueId();
  });

  const maxQuestionCount = 15;

  const { playSound, stopSound } = useSound();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!gameFlow.gameOver && !showScore && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => Math.max(0, prevTimeLeft - 1));
      }, 1000);
    }

    if (timeLeft === 0 && !gameFlow.gameOver && !showScore) {
      setGameFlow((prevState) => ({
        ...prevState,
        gameOver: true,
        playing: false,
      }));
      const safeLevelPrize = getSafeLevelPrize(safeLevel);
      setScore(safeLevelPrize);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timeLeft, gameFlow.gameOver, showScore, safeLevel, setGameFlow]);

  const fetchNewQuestion = useCallback(async () => {
    try {
      const question = await getRandomQuestion();
      setQuestion(question as Question);

      // Only reset these values if it's a new game session
      if (!gameFlow.playing) {
        setCurrentQuestion(0);
        setTimeLeft(30);
        setSafeLevel(0);
        setScore(0);
      }

      setGameFlow((prev) => ({ ...prev, playing: true }));
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  }, [
    gameFlow.playing,
    setQuestion,
    setCurrentQuestion,
    setTimeLeft,
    setSafeLevel,
    setScore,
    setGameFlow,
  ]);

  useEffect(() => {
    if (!gameFlow.gameOver && !showScore) {
      fetchNewQuestion();
    }
  }, [gameFlow.gameOver, showScore, fetchNewQuestion]);

  const getSafeLevelPrize = (level) => {
    switch (level) {
      case 1:
        return 1000;
      case 2:
        return 32000;
      default:
        return 0;
    }
  };

  const handleAnswerOptionClick = async (selectedAnswer: string) => {
    const isCorrect = selectedAnswer === question.correctAnswer;

    try {
      // Get the current sessionId from localStorage
      const currentSessionId = localStorage.getItem("currentGameSession");

      if (!currentSessionId) {
        console.error("No active game session found");
        return;
      }

      // Get current game data first
      const docRef = doc(db, "games", currentSessionId); // Use currentSessionId instead of sessionId state
      const docSnap = await getDoc(docRef);
      const currentGameData = docSnap.data();

      const updateData = {
        ...currentGameData,
        score: isCorrect ? score : getSafeLevelPrize(safeLevel),
        status: isCorrect ? "active" : "finished",
        finishedAt: isCorrect ? null : new Date(),
        currentQuestion: currentQuestion,
        questions: currentGameData?.questions
          ? [...currentGameData.questions, question.id].filter(Boolean)
          : question.id
          ? [question.id]
          : [],
      };

      await updateGameSession(currentSessionId, updateData); // Use currentSessionId here too

      if (question.id) {
        const questionHistoryEntry = {
          questionId: question.id,
          answer: selectedAnswer,
          isCorrect,
          timestamp: new Date(),
        };
        await updateQuestionHistory(currentSessionId, questionHistoryEntry); // And here
      }

      if (isCorrect) {
        playSound("correct");

        setTimeout(async () => {
          if (question.id) {
            await markQuestionAsAnswered(question.id);
          }

          const reversedIndex = moneyPyramid.length - currentQuestion - 1;
          const prizeAmountObject = moneyPyramid[reversedIndex];
          const cleanedAmount = prizeAmountObject.amount
            .replace(/[$.,]/g, "")
            .trim();
          const formattedPrizeAmount = Number(cleanedAmount);

          setScore(formattedPrizeAmount);

          const nextQuestion = currentQuestion + 1;
          if (nextQuestion < maxQuestionCount) {
            try {
              const newQuestion = await getRandomQuestion();
              setQuestion(newQuestion as Question);
              setCurrentQuestion(nextQuestion);
              setTimeLeft(30);

              if (nextQuestion === 5) {
                setSafeLevel(1);
              } else if (nextQuestion === 10) {
                setSafeLevel(2);
              }
            } catch (error) {}
          } else {
            setShowScore(true);
            const currentSessionId = localStorage.getItem("currentGameSession");
            if (currentSessionId) {
              await updateGameSession(currentSessionId, {
                status: "finished",
                finishedAt: new Date(),
                score: formattedPrizeAmount,
              });
            }
          }
        }, 1000);
      } else {
        playSound("wrong");
        setTimeout(async () => {
          setGameFlow((prevState) => ({
            ...prevState,
            gameOver: true,
            playing: false,
          }));
          const safeLevelPrize = getSafeLevelPrize(safeLevel);
          setScore(safeLevelPrize);

          await updateGameSession(currentSessionId, {
            status: "finished",
            finishedAt: new Date(),
            score: safeLevelPrize,
          });
        }, 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowBanner(true);
      setTimeout(() => setShowBanner(false), 3000); // Hide banner after 3 seconds
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowBanner(true); // Don't set timeout when offline
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Show banner immediately if offline at component mount
    if (!navigator.onLine) {
      setShowBanner(true);
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (gameFlow.playing) {
      playSound("ingame");
      stopSound("ambient");
      stopSound("gameOver");
    } else {
      stopSound("ingame");
    }

    // Cleanup when component unmounts
    return () => {
      stopSound("ingame");
    };
  }, [gameFlow.playing, playSound, stopSound]); // Only react to playing state changes

  useEffect(() => {
    if (gameFlow.gameOver) {
      stopSound("ingame");
      playSound("gameOver");

      // Store the current gameFlow state in a ref or use cleanup
      const timeoutId = setTimeout(() => {
        stopSound("gameOver");
        // Only play ambient if we're still in gameOver state
        if (gameFlow.gameOver && !gameFlow.playing) {
          playSound("ambient");
        }
      }, 8000);

      // Cleanup to prevent memory leaks and unwanted sound plays
      return () => {
        clearTimeout(timeoutId);
        stopSound("gameOver");
      };
    }
  }, [gameFlow.gameOver]);

  useEffect(() => {
    if (gameFlow.gameOver || showScore) {
      localStorage.removeItem("currentGameSession");
    }
  }, [gameFlow.gameOver, showScore]);

  useEffect(() => {
    const currentSessionId = localStorage.getItem("currentGameSession");
    if (currentSessionId && currentSessionId !== sessionId) {
      setSessionId(currentSessionId);
    }
  }, []);

  return (
    <>
      <SoundProvider>
        <div className="inGameLayout">
          <div className="blurBg"></div>
          <Debugger data={gameFlow} label="gameFlow" />
          <div
            style={{
              backgroundColor: isOnline ? "#4CAF50" : "#ff4444",
              color: "white",
              padding: "10px",
              textAlign: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              transform: showBanner ? "translateY(0)" : "translateY(-100%)",
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
            }}
          >
            {isOnline ? "You're online, everything is fine" : "You're offline"}
          </div>
          {gameFlow.gameOver ? (
            <EndGameLayout score={score} />
          ) : showScore ? (
            <div className="score-section">
              <h1>Congratulations! 🎉</h1>
              <h2>You won DEVQUIZ!</h2>
              <p>Your Salary: ${score.toLocaleString()} 💰</p>
            </div>
          ) : (
            <div className="game-container">
              <div className="main-content">
                <Trivia
                  question={question}
                  currentQuestion={currentQuestion}
                  timeLeft={timeLeft}
                  handleAnswerOptionClick={handleAnswerOptionClick}
                />
              </div>
              <MoneyPyramid
                moneyPyramid={moneyPyramid}
                currentQuestion={currentQuestion}
              />
            </div>
          )}
          {gameFlow.playing && <Footer />}
          <SoundControl />
        </div>
      </SoundProvider>
    </>
  );
};

export default InGameLayout;

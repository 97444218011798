import React, { createContext, useContext, useState, useEffect, useCallback } from "react";

interface SoundContextType {
  playSound: (soundName: string) => void;
  stopSound: (soundName: string) => void;
  toggleMute: () => void;
  isMuted: boolean;
  volume: number;
  setVolume: (volume: number) => void;
}

const SoundContext = createContext<SoundContextType | undefined>(undefined);

const createAudio = (path: string) => {
  const audio = new Audio(path);
  audio.preload = "auto";
  return audio;
};

const defaultVolumes = {
  ingame: 0.05,
  correct: 0.08,
  wrong: 0.08,
  gameOver: 0.08,
  ambient: 0.03,
};

const sounds = {
  ingame: createAudio("/sounds/clubbed-to-death.mp3"),
  correct: createAudio("/sounds/correct.mp3"),
  wrong: createAudio("/sounds/wrong.mp3"),
  gameOver: createAudio("/sounds/game-over.mp3"),
  ambient: createAudio("/sounds/ambient.mp3"),
};

export const SoundProvider = ({ children }: { children: React.ReactNode }) => {
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);

  // Initialize volumes
  useEffect(() => {
    const savedVolume = localStorage.getItem("soundVolume");
    if (savedVolume !== null) {
      const parsedVolume = parseFloat(savedVolume);
      if (!isNaN(parsedVolume) && parsedVolume >= 0 && parsedVolume <= 1) {
        setVolume(parsedVolume);
      }
    }
  }, []);

  // Handle volume changes
  useEffect(() => {
    Object.entries(sounds).forEach(([soundName, sound]) => {
      const baseVolume =
        defaultVolumes[soundName as keyof typeof defaultVolumes];
      const calculatedVolume = Math.min(baseVolume * volume, 1);
      sound.volume = calculatedVolume;
      sound.muted = isMuted;
    });
  }, [volume, isMuted]);

  const playSound = useCallback((soundName: string) => {
    const sound = sounds[soundName as keyof typeof sounds];
    if (sound && !isMuted) {
      // Don't reload the sound if it's already playing
      if (sound.currentTime > 0 && !sound.paused) {
        return;
      }

      // Reset time and ensure proper volume before playing
      sound.currentTime = 0;
      const baseVolume =
        defaultVolumes[soundName as keyof typeof defaultVolumes];
      sound.volume = Math.min(baseVolume * volume, 1);

      if (sound.readyState < 2) {
        sound.load();
        sound.addEventListener(
          "canplaythrough",
          () => {
            sound
              .play()
              .then(() => {})
              .catch((error) => {});
          },
          { once: true }
        );
      } else {
        sound
          .play()
          .then(() => {})
          .catch((error) => {});
      }
    }
  }, [isMuted, volume]);

  const stopSound = useCallback((soundName: string) => {
    const sound = sounds[soundName as keyof typeof sounds];
    if (sound) {
      try {
        sound.pause();
        sound.currentTime = 0;
      } catch (error) {}
    }
  }, []);

  const setVolumeWithValidation = (newVolume: number) => {
    const validatedVolume = Math.max(0, Math.min(1, newVolume));
    setVolume(validatedVolume);
    localStorage.setItem("soundVolume", validatedVolume.toString());
  };

  const value = {
    playSound,
    stopSound,
    toggleMute: () => {
      setIsMuted((prev) => {
        const newValue = !prev;
        localStorage.setItem("soundMuted", newValue.toString());
        return newValue;
      });
    },
    isMuted,
    volume,
    setVolume: setVolumeWithValidation,
  };

  return (
    <SoundContext.Provider value={value}>{children}</SoundContext.Provider>
  );
};

export const SoundControl = () => {
  const { toggleMute, isMuted, volume, setVolume } = useSound();
  const [isHovered, setIsHovered] = useState(false);

  // Load saved volume on mount
  useEffect(() => {
    const savedVolume = localStorage.getItem("soundVolume");
    const savedMuted = localStorage.getItem("soundMuted");

    if (savedVolume !== null) {
      setVolume(parseFloat(savedVolume));
    }

    if (savedMuted !== null) {
      // If you need to handle mute state persistence
      if ((savedMuted === "true") !== isMuted) {
        toggleMute();
      }
    }
  }, [toggleMute, isMuted, setVolume]);

  // Save volume when it changes
  useEffect(() => {
    localStorage.setItem("soundVolume", volume.toString());
  }, [volume]);

  // Save muted state when it changes
  useEffect(() => {
    localStorage.setItem("soundMuted", isMuted.toString());
  }, [isMuted]);

  const volumePercentage = Math.round(volume * 100);
  const isEffectivelyMuted = isMuted || volumePercentage === 0;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: "fixed",
        top: "10px",
        right: "10px",
        background: isHovered ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.3)",
        padding: "8px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        zIndex: 1000,
        transition: "all 0.3s ease",
        opacity: isHovered ? 1 : 0.3,
        transform: `scale(${isHovered ? 1 : 0.8})`,
      }}
    >
      <button
        onClick={toggleMute}
        style={{
          background: "none",
          border: "none",
          color: "white",
          cursor: "pointer",
          opacity: isHovered ? 1 : 0.7,
          padding: "4px",
          transition: "opacity 0.3s ease",
        }}
      >
        {isEffectivelyMuted ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M11 5L6 9H2v6h4l5 4V5z" />
            <line x1="23" y1="9" x2="17" y2="15" />
            <line x1="17" y1="9" x2="23" y2="15" />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M11 5L6 9H2v6h4l5 4V5z" />
            <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
          </svg>
        )}
      </button>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px",
          opacity: isHovered ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
      >
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={(e) => setVolume(parseFloat(e.target.value))}
          style={{
            width: "60px",
            height: "2px",
            accentColor: "white",
          }}
        />
        <span
          style={{
            color: "white",
            fontSize: "10px",
            fontFamily: "Arial",
            opacity: 0.8,
          }}
        >
          {volumePercentage}%
        </span>
      </div>
    </div>
  );
};

export const useSound = () => {
  const context = useContext(SoundContext);
  if (!context) throw new Error("useSound must be used within SoundProvider");
  return context;
};

import React from "react";
import "./style.css"; // CSS dosyasını içe aktar

interface HeadlineProps {
  text: string;
  style?: React.CSSProperties; // Optional style prop
}

const Headline: React.FC<HeadlineProps> = ({ text, style }) => {
  return (
    <div className="headline" style={style}>
      <h2>{text}</h2>
    </div>
  );
};

export default Headline;

import React, { useState, useEffect, useMemo } from "react";
import "./styles.css"; // CSS dosyasını oluşturmanız gerekecek

const CountdownBar = ({ seconds, countdown }) => {
  const [barWidth, setBarWidth] = useState("100%");
  const [remainingTime, setRemainingTime] = useState(seconds);
  const [pulseEmoji, setPulseEmoji] = useState("🔴");
  //TODO: APP_NAME'i config'den al
  const APP_NAME = "DEVQUIZ";

  const getBarColor = useMemo(
    () => (time) => {
      if (time <= 5) return "#ff4444"; // red
      if (time <= 10) return "#ffa726"; // orange
      if (time <= 15) return "#ffeb3b"; // yellow
      if (time <= 20) return "#9ccc65"; // light green
      return "#4caf50"; // green
    },
    []
  );

  useEffect(() => {
    setRemainingTime(seconds);
    document.title = `${APP_NAME} | ${seconds} ${pulseEmoji}`;

    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime >= 0) {
          setBarWidth(`${(newTime / countdown) * 100}%`);
          setPulseEmoji((prev) => (prev === "🔴" ? "⭕" : "🔴"));
          document.title = `${APP_NAME} | ${newTime} ${pulseEmoji}`;
          return newTime;
        }
        document.title = `${APP_NAME} | Time's Up ⚠️`;
        clearInterval(interval);
        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      document.title = APP_NAME;
    };
  }, [seconds, countdown, pulseEmoji]);

  const calculateMaxWidth = (seconds) => {
    const maxPercent = 100;
    const reduceRate = 0.05;
    const maxPercentage = maxPercent - seconds * reduceRate;
    return `${maxPercentage}%`;
  };

  return (
    <div className="countdown-container" style={{ position: "relative" }}>
      <div
        className="countdown-bar"
        role="progressbar"
        aria-valuenow={remainingTime}
        aria-valuemin={0}
        aria-valuemax={seconds}
        style={{
          width: barWidth,
          maxWidth: calculateMaxWidth(seconds),
          backgroundColor: getBarColor(remainingTime),
          transition: "width 1s linear, background-color 0.5s ease-in-out",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
          textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
        }}
      >
        {remainingTime}s
      </div>
    </div>
  );
};

export default CountdownBar;

import React, { createContext, useContext, useState } from "react";

interface GameFlowState {
  start: boolean;
  playing: boolean;
  paused: boolean;
  gameOver: boolean;
}

interface GameFlowContextType {
  gameFlow: GameFlowState;
  setGameFlow: React.Dispatch<React.SetStateAction<GameFlowState>>;
}

const GameFlowContext = createContext<GameFlowContextType | undefined>(
  undefined
);

export const GameFlowProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [gameFlow, setGameFlow] = useState<GameFlowState>({
    start: false,
    playing: false,
    paused: false,
    gameOver: false,
  });

  return (
    <GameFlowContext.Provider value={{ gameFlow, setGameFlow }}>
      {children}
    </GameFlowContext.Provider>
  );
};

export const useGameFlow = () => {
  const context = useContext(GameFlowContext);
  if (context === undefined) {
    throw new Error("useGameFlow must be used within a GameFlowProvider");
  }
  return context;
};

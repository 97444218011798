import React from "react";
import "./style.css";

interface MoneyPyramidProps {
  moneyPyramid: { id: number; amount: string }[];
  currentQuestion: number;
}

const MoneyPyramid: React.FC<MoneyPyramidProps> = ({
  moneyPyramid,
  currentQuestion,
}) => {
  return (
    <div className="moneypyramid">
      <ul>
        {moneyPyramid.map((item) => {
          const isActive = currentQuestion + 1 === item.id;
          const isCurrentPrize = currentQuestion >= item.id;
          const classNames = [
            isActive ? "active" : "",
            isCurrentPrize ? "currentprize" : "",
            item.id % 5 === 0 ? "safe-level" : "not-safe-level",
          ]
            .filter(Boolean)
            .join(" ");
          return (
            <li key={item.id} className={classNames}>
              <span className="id">{item.id}</span>
              <span className="amount">{item.amount}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MoneyPyramid;

import React, { useState } from "react";
import CountdownBar from "../countdownbar/index.tsx";

interface Question {
  questionText: string;
  answerOptions: string[];
  correctAnswer: string;
  id?: string;
}

interface TriviaProps {
  question: Question;
  timeLeft: number;
  currentQuestion: number;
  handleAnswerOptionClick: (selectedAnswer: string) => void;
}

const Trivia: React.FC<TriviaProps> = ({
  question,
  timeLeft,
  handleAnswerOptionClick,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = (answerOption: string) => {
    setIsDisabled(true);
    setSelectedAnswer(answerOption);
    handleAnswerOptionClick(answerOption);

    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  const getButtonClass = (answerOption: string) => {
    const isCorrect = selectedAnswer === question.correctAnswer;
    return selectedAnswer === answerOption && selectedAnswer !== question.correctAnswer
      ? "answer-button wrong"
      : isCorrect && answerOption === question.correctAnswer
      ? "answer-button correct"
      : "answer-button";
  };

  return (
    <div className="trivia">
      <div className="question-section">
        <CountdownBar seconds={timeLeft} countdown={30} />
        <div className="question-text">{question.questionText}</div>
        <div className="answer-section" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {question.answerOptions.map((answerOption, index) => {
            return (
              <button
                key={index}
                onClick={() => handleClick(answerOption)}
                className={getButtonClass(answerOption)}
                disabled={isDisabled}
                style={{ flex: '0 0 calc(50% - 5px)' }}
              >
                {answerOption}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Trivia;

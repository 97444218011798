import React from "react";

interface LogoProps {
  src?: string;
  alt: string;
  style?: React.CSSProperties;
}

const Logo: React.FC<LogoProps> = ({
  src = "/devquizlogo.png",
  alt,
  style,
}) => {
  return <img src={src} alt={alt} style={style} />;
};

export default Logo;

import React, { useState } from "react";

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);

  const footerStyle = {
    position: "fixed" as const,
    bottom: "10px",
    right: "10px",
    padding: "8px",
    backgroundColor: isHovered ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.2)",
    backdropFilter: "blur(5px)",
    borderRadius: "20px",
    display: "flex",
    gap: "15px",
    transition: "all 0.3s ease",
    zIndex: 1000,
  };

  const iconStyle = {
    color: isHovered ? "#fff" : "rgba(255, 255, 255, 0.5)",
    transition: "all 0.2s ease",
    width: "20px",
    height: "20px",
  };

  return (
    <footer
      style={footerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a
        href="https://twitter.com/deusmur"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...iconStyle }}
      >
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
        </svg>
      </a>

      <a
        href="https://github.com/muratdemirci"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...iconStyle }}
      >
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
        </svg>
      </a>

      <a
        href="https://www.buymeacoffee.com/muratdemirci"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...iconStyle }}
      >
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M18 8h1a4 4 0 0 1 0 8h-1" />
          <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />
          <line x1="6" y1="1" x2="6" y2="4" />
          <line x1="10" y1="1" x2="10" y2="4" />
          <line x1="14" y1="1" x2="14" y2="4" />
        </svg>
      </a>
    </footer>
  );
};

export default Footer;

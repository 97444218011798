import React, { useState } from "react";
import PlayAgainLayout from "../playagain/index.tsx";
import InGameLayout from "../ingame/index.tsx";
import Footer from "../../components/footer/index.tsx";
import "./styles.css";
import { config } from "../../config/main";

const EndGameLayout = ({ score }) => {
  const [inGame, setInGame] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const GAME_URL = config.GAME_URL;

  const generateShareText = () => {
    return `My developer salary is $${score.toLocaleString()}! Can you earn more? 💻 ⚡ ${GAME_URL} #DEVQUIZ`;
  };

  const handleShare = async (platform: string) => {
    if (isSharing) return;

    try {
      setIsSharing(true);

      const shareText = generateShareText();
      const encodedText = encodeURIComponent(shareText);

      switch (platform) {
        case "twitter":
          window.open(
            `https://twitter.com/intent/tweet?text=${encodedText}`,
            "_blank"
          );
          break;
        case "facebook":
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              GAME_URL
            )}`,
            "_blank"
          );
          break;
        case "whatsapp":
          window.open(
            `https://api.whatsapp.com/send?text=${encodedText}`,
            "_blank"
          );
          break;
        case "native":
          if (navigator.share) {
            await navigator.share({
              title: "DEVQUIZ Score!",
              text: shareText,
              url: GAME_URL,
            });
          }
          break;
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsSharing(false);
      }, 1000);
    }
  };

  if (inGame) {
    return <InGameLayout />;
  }

  return (
    <>
      <div className="endgame-container">
        <div className="content-overlay">
          <h1>Game Over! 🎮</h1>
          <h2>Thanks for playing DEVQUIZ!</h2>
          <p>Your Salary: ${score.toLocaleString()} 💰</p>
          <PlayAgainLayout setInGame={setInGame} />
        </div>

        <div className="buttons-container">
          <div className="share-text">↓ share your salary↓</div>
          <div className="share-options">
            <button
              onClick={() => handleShare("twitter")}
              className="share-option twitter"
              disabled={isSharing}
            >
              𝕏
            </button>
            <button
              onClick={() => handleShare("facebook")}
              className="share-option facebook"
              disabled={isSharing}
            >
              Facebook
            </button>
            <button
              onClick={() => handleShare("whatsapp")}
              className="share-option whatsapp"
              disabled={isSharing}
            >
              WhatsApp
            </button>
            {typeof navigator.share === "function" && (
              <button
                onClick={() => handleShare("native")}
                className="share-option native"
                disabled={isSharing}
              >
                Phone 📱
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EndGameLayout;

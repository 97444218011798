import React from "react";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  type?: "button" | "submit";
  style?: React.CSSProperties;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  backgroundColor,
  textColor,
  onClick,
  children,
  style,
  type,
  disabled,
}) => {
  const buttonStyle: React.CSSProperties = {
    backgroundColor,
    color: textColor,
    padding: "10px 30px",
    border: "1px solid rgba(0,0,0,0.4)",
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    boxShadow:
      "0 3px 2px rgba(0,0,0,0.2), inset 0 5px 0 rgba(0,0,0,0.1), inset 0 -10px 15px rgba(0,0,0,0.1)",
    position: "relative",
    overflow: "hidden",
    transition: "background-color 0.3s ease",
    ...style,
  };

  const gradientStyle = {
    position: "absolute" as "absolute",
    borderRadius: "30px",
    width: "80%",
    height: "30%",
    background:
      "linear-gradient(25deg, rgba(255,255,255,0.1), rgba(255,255,255,0.15))",
    left: "50%",
    marginLeft: "-40%",
    top: "18%",
    zIndex: 9999,
  };

  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      <div style={gradientStyle}></div>
      {children}
    </button>
  );
};

export default Button;

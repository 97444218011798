import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firestore.js";

const questionLimit = 15;
let answeredQuestions = new Set(); // Doğru cevaplanmış soruları tutacak set

export const getRandomQuestion = async () => {
  try {
    let availableIds = [];
    for (let i = 1; i <= questionLimit; i++) {
      if (!answeredQuestions.has(i.toString())) {
        availableIds.push(i);
      }
    }

    if (availableIds.length === 0) {
      answeredQuestions.clear();
      availableIds = Array.from({ length: questionLimit }, (_, i) => i + 1);
    }

    const randomIndex = Math.floor(Math.random() * availableIds.length);
    const randomId = availableIds[randomIndex];
    const randomDocId = randomId.toString();

    // Soruyu al
    const questionDocRef = doc(collection(db, "quizQuestions"), randomDocId);
    const questionDocSnap = await getDoc(questionDocRef);

    // Doğru cevap indeksini al
    const correctAnswerDocRef = doc(
      collection(db, "correctAnswers"),
      randomDocId
    );
    const correctAnswerDocSnap = await getDoc(correctAnswerDocRef);

    if (!questionDocSnap.exists() || !correctAnswerDocSnap.exists()) {
      throw new Error(`Question or answer with ID ${randomDocId} not found`);
    }

    const questionData = questionDocSnap.data();
    const correctAnswerData = correctAnswerDocSnap.data();

    // Doğru cevabı answerOptions dizisinden al
    const correctAnswer =
      questionData.answerOptions[correctAnswerData.correctAnswerIndex];

    return {
      ...questionData,
      id: randomDocId,
      correctAnswer: correctAnswer,
    };
  } catch (e) {
    throw e;
  }
};

// Doğru cevaplanmış soruyu kaydet
export const markQuestionAsAnswered = (questionId) => {
  answeredQuestions.add(questionId);
};
